import React from 'react'

const UpdateSuppliesDialog = (openUpdateDialog ,
    setOpenUpdateDialog ,
    formData ,
    setFormData ,
    handleUpdate) => {
  return (
    <div>UpdateSupplies</div>
  )
}

export default UpdateSuppliesDialog